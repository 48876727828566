<template>
	<v-container>
		<v-card flat color="transparent">
			<div class="title">Welcome back, {{ $auth.user().name }}</div>
		</v-card>
	</v-container>
</template>

<script>
export default{
	data(){
		return {

		}
	},
	created(){

	},
	methods:{

	}
}
</script>